import React from 'react';
import GameBoyColor from './components/GameBoyColor/GameBoyColor';
import './App.css'

function App() {
  return (
    <>
      <GameBoyColor/>
    </>
  );
}

export default App;
